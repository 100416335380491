import axios from "axios";

export const formattingPrice = (price: number) => {
  var str = price.toString().split(".");
  if (str[0].length > 3) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "TZS1,");
  }
  if (str[1] && str[1].length > 3) {
    str[1] = str[1].replace(/(\d{3})/g, "TZS1 ");
  }
  return " " + str.join(".");
};

function urlBase64Decode(str: string) {
  let output = str.replace(/-/g, "+").replace(/_/g, "/");
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += "==";
      break;
    case 3:
      output += "=";
      break;
    default:
      throw "Illegal base64url string!";
  }
  return decodeURIComponent((window as any).escape(window.atob(output)));
}

export const getUserId = (token: string) => {
  if (token === null || token === "") return;
  const parts = token.split(".");
  if (parts.length !== 3) {
    throw new Error("JWT must have 3 parts");
  }
  const decoded = urlBase64Decode(parts[1]);
  if (!decoded) {
    throw new Error("Cannot decode the token");
  }
  const decodeID = JSON.parse(decoded);
  return decodeID.id;
};

export const toCurrency = (n, curr = "TZS", LanguageFormat = undefined) =>
  Intl.NumberFormat(LanguageFormat, {
    style: "currency",
    currency: curr,
  }).format(n);

export const slugify = (text: string) => {
  if (text == null) {
    return "";
  }

  return (
    text
      .normalize("NFD") // split an accented letter in the base letter and the acent
      .replace("-", " ")
      .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
      .toLowerCase()
      // .trim()
      .trimStart()
      .replace(/[^a-z0-9 -]/g, "") // remove all chars not letters, numbers, dash and spaces (to be replaced)
      .replace(/\s+/g, "-")
  ); // separator
};

export async function uploadSignedPostUrlFile(formPostUrl, data) {
  const file = data["file"];

  const formData = new FormData();
  formData.append("key", data["key"]);
  formData.append("Content-Type", file.type);
  formData.append("bucket", data["bucket"]);
  formData.append("X-Amz-Algorithm", data["X-Amz-Algorithm"]);
  formData.append("X-Amz-Credential", data["X-Amz-Credential"]);
  formData.append("X-Amz-Date", data["X-Amz-Date"]);
  formData.append("Policy", data["Policy"]);
  formData.append("X-Amz-Signature", data["X-Amz-Signature"]);

  formData.append("file", file);

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return axios.post(formPostUrl, formData, config);
}
