import { useQuery, gql } from '@apollo/client';
import React, { useState, useCallback } from 'react';
import { getUserId } from 'utility/utility';
const GET_CATEGORY_TYPES = gql`
  query getCategoryTypes($adminId: String, $searchBy: String) {
    category_types(adminId: $adminId, searchBy: $searchBy) {
      id
      value
      name
      label
      adminId
    }
  }
`;
 type Props = any;

export const CategoryTypes: React.FC<Props> =  (props) => {
    const { data, error, refetch } = useQuery(GET_CATEGORY_TYPES, {
      variables: {
        adminId: getUserId(localStorage.getItem("userSessionToken")),
      }
    });
    if (error) {
      return <div>Error! {error.message}</div>;
    }
    props.setTypes (data)

    return (
        <div />
    )
}
