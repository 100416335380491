import React, { useCallback, useState } from 'react';
import { withStyle } from 'baseui';
import { Grid, Row as Rows, Col as Column } from 'components/FlexBox/FlexBox';
import { useDrawerDispatch } from 'context/DrawerContext';
import Select from 'components/Select/Select';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Checkbox from 'components/CheckBox/CheckBox';
import { useQuery, gql } from '@apollo/client';
import { Wrapper, Header, Heading } from 'components/Wrapper.style';
import { CategoryTypes } from '../CategoryTypes/CategoryTypes'
import {
  TableWrapper,
  StyledTable,
  StyledHeadCell,
  StyledCell,
} from './SubCategoryView.style';
import { Plus } from 'assets/icons/Plus';
import NoResult from 'components/NoResult/NoResult';
import { getUserId } from 'utility/utility';

const GET_SUB_CATEGORIES = gql`
  query getSubCategories($type: String, $searchBy: String, $adminId: String) {
    subcategories(type: $type, searchBy: $searchBy, adminId: $adminId) {
      id
      name
      slug
      value
      catId
      type
      adminId
    }
  }
`;
const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0,
    },
  },
}));

const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center',
  },
}));


export default function SubCategoryView() {
const [category, setCategory] = useState([]);
  const [search, setSearch] = useState('');
  const dispatch = useDrawerDispatch();
  const [checkedId, setCheckedId] = useState([]);
  const [checked, setChecked] = useState(false);
  const [categorytypes, setCategoryTypes] = useState([])
  const admin_id = getUserId(localStorage.getItem("userSessionToken"))
  const openDrawer = useCallback(
    () => dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'SUB_CATEGORY_FORM' }),
    [dispatch]
  );
  const openDrawerEditPopup = useCallback(
    (item) => {
      dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'SUBCATEGORY_EDITFORM', data: item })},
    [dispatch]
  );
  const { data, error, refetch } = useQuery(GET_SUB_CATEGORIES , {
    variables: {
      type: null,
      adminId: admin_id,
      searchBy: ''
    }
  });
  if (error) {
    return <div>Error! {error.message}</div>;
  }

  function handleSearch(event) {
    const value = event.currentTarget.value;
    setSearch(value);
    refetch({
      type: category.length ? category[0].value : null,
      adminId: admin_id,
      searchBy: value,
    });
  }


  let num = 1;

  function handleCategory({ value }) {
    setCategory(value);
    if (value.length) {
      refetch({
        type: value[0].value,
        adminId: admin_id
      });
    } else {
      refetch({
        type: null,
        adminId: admin_id
      });
    }
  }

  function onAllCheck(event) {
    if (event.target.checked) {
      const idx = data && data.subcategories.map((current) => current.id);
      setCheckedId(idx);
    } else {
      setCheckedId([]);
    }
    setChecked(event.target.checked);
  }

  function handleCheckbox(event) {
    const { name } = event.currentTarget;
    if (!checkedId.includes(name)) {
      setCheckedId((prevState) => [...prevState, name]);
    } else {
      setCheckedId((prevState) => prevState.filter((id) => id !== name));
    }
  }
  function openPopup(id) {
     const orderData = Object.values(data.subcategories);
    orderData.map((item) => {
      const sID = Object.values(item)
      if (sID[1] === id) {
        openDrawerEditPopup(item);
      }
      return item;
    })
  }
  const SettingCategoryTypes = (value) => {
    setCategoryTypes(value)
  }

  if(categorytypes && categorytypes['category_types'] && categorytypes['category_types'].length) {
    return (
      <Grid fluid={true}>
        <Row>
          <Col md={12}>
            <Header
              style={{
                marginBottom: 30,
                boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
              }}
            >
              <Col md={2}>
                <Heading>SubCategory</Heading>
              </Col>

              <Col md={10}>
                <Row>
                  <Col md={3} lg={3}>
                    <Select
                      options={categorytypes}
                      labelKey="label"
                      valueKey="value"
                      placeholder="Category Type"
                      value={category}
                      searchable={false}
                      onChange={handleCategory}
                    />
                  </Col>

                  <Col md={5} lg={6}>
                    <Input
                      value={search}
                      placeholder="Ex: Search By Name or Slug"
                      onChange={handleSearch}
                      clearable
                    />
                  </Col>

                  <Col md={4} lg={3}>
                    <Button
                      onClick={openDrawer}
                      startEnhancer={() => <Plus />}
                      overrides={{
                        BaseButton: {
                          style: () => ({
                            width: '100%',
                            borderTopLeftRadius: '3px',
                            borderTopRightRadius: '3px',
                            borderBottomLeftRadius: '3px',
                            borderBottomRightRadius: '3px',
                          }),
                        },
                      }}
                    >
                      Add SubCategory
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Header>

            <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
              <TableWrapper>
                <StyledTable $gridTemplateColumns="minmax(70px, 70px) minmax(70px, 70px) minmax(150px, auto) minmax(150px, auto) minmax(150px, auto) auto">
                  <StyledHeadCell>
                    <Checkbox
                      type="checkbox"
                      value="checkAll"
                      checked={checked}
                      onChange={onAllCheck}
                      overrides={{
                        Checkmark: {
                          style: {
                            borderTopWidth: '2px',
                            borderRightWidth: '2px',
                            borderBottomWidth: '2px',
                            borderLeftWidth: '2px',
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px',
                            borderBottomRightRadius: '4px',
                            borderBottomLeftRadius: '4px',
                          },
                        },
                      }}
                    />
                  </StyledHeadCell>
                  <StyledHeadCell>Id</StyledHeadCell>
                  <StyledHeadCell>Name</StyledHeadCell>
                  <StyledHeadCell>Slug</StyledHeadCell>
                  <StyledHeadCell>Category</StyledHeadCell>
                  <StyledHeadCell>Category Type</StyledHeadCell>

                  {data ? (
                    data.subcategories.length ? (
                      data.subcategories
                        .map((item) => Object.values(item))
                        .map((row, index) => (
                          <React.Fragment key={index}>
                            <StyledCell>
                              <Checkbox
                                name={row[1]}
                                checked={checkedId.includes(row[1])}
                                onChange={handleCheckbox}
                                overrides={{
                                  Checkmark: {
                                    style: {
                                      borderTopWidth: '2px',
                                      borderRightWidth: '2px',
                                      borderBottomWidth: '2px',
                                      borderLeftWidth: '2px',
                                      borderTopLeftRadius: '4px',
                                      borderTopRightRadius: '4px',
                                      borderBottomRightRadius: '4px',
                                      borderBottomLeftRadius: '4px',
                                    },
                                  },
                                }}
                              />
                            </StyledCell>
                            <StyledCell onClick={openPopup.bind(this, row[1])} style={{ cursor: 'pointer' }}>{num++}</StyledCell>
                            <StyledCell onClick={openPopup.bind(this, row[1])} style={{ cursor: 'pointer' }}>{row[4]}</StyledCell>
                            <StyledCell onClick={openPopup.bind(this, row[1])} style={{ cursor: 'pointer' }}>{row[3]}</StyledCell>
                            <StyledCell onClick={openPopup.bind(this, row[1])} style={{ cursor: 'pointer' }}>{row[3].split("/")[0]}</StyledCell>
                            <StyledCell onClick={openPopup.bind(this, row[1])} style={{ cursor: 'pointer' }}>{row[6]}</StyledCell>

                          </React.Fragment>
                        ))
                    ) : (
                      <NoResult
                        hideButton={false}
                        style={{
                          gridColumnStart: '1',
                          gridColumnEnd: 'one',
                        }}
                      />
                    )
                  ) : null}
                </StyledTable>
              </TableWrapper>
            </Wrapper>
          </Col>
        </Row>
      </Grid>
    );
  }
  else {
    return(
      <CategoryTypes setTypes = { SettingCategoryTypes } />
    )
  }
}
