// import React from 'react';

// type AuthProps = {
//   isAuthenticated: boolean;
//   authenticate: Function;
//   signout: Function;
// };

// export const AuthContext = React.createContext({} as AuthProps);

// const isValidToken = () => {
//   const token = localStorage.getItem('userSessionToken');
//   // JWT decode & check token validity & expiration.
//   if (token) return true;
//   return false;
// };

// const AuthProvider = (props: any) => {
//   const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());
//   function authenticate({ email, password }, cb) {
//     makeAuthenticated(true);
//     localStorage.setItem('userSessionToken', `${email}.${password}`);
//     setTimeout(cb, 100); // fake async
//   }
//   function signout(cb) {
//     makeAuthenticated(false);
//     localStorage.removeItem('userSessionToken');
//     setTimeout(cb, 100);
//   }
//   return (
//     <AuthContext.Provider
//       value={{
//         isAuthenticated,
//         authenticate,
//         signout,
//       }}
//     >
//       <>{props.children}</>
//     </AuthContext.Provider>
//   );
// };

// export default AuthProvider;

import React from "react";
import { getUserId } from "../utility/utility";

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signout: Function;
};

export const AuthContext = React.createContext({} as AuthProps);

const isValidToken = () => {
  const token = localStorage.getItem("userSessionToken");
  const id = getUserId(localStorage.getItem("userSessionToken"));
  // JWT decode & check token validity & expiration.
  if (token) return true;
  return false;
};

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());
  async function authenticate({ username, password }, cb) {
    const requestOptions = {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        email_address: username,
        password: password,
      }),
    };
    const response = await fetch(
      `${process.env.REACT_APP_AUTHENTICATION_URL}/login`,
      requestOptions
    );
    const data = await response.json();
    if (data.success) {
      makeAuthenticated(true);
      await localStorage.setItem("userSessionToken", data.token);
      return;
    }
    alert(`Error! ${data.message}`);
    setTimeout(cb, 100);
  }
  function signout(cb) {
    makeAuthenticated(false);
    localStorage.removeItem("userSessionToken");
    setTimeout(cb, 100);
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        authenticate,
        signout,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;

